{
  "name": "etz",
  "version": "v1.1.22",
  "private": true,
  "scripts": {
    "prebuild": "mkdir -p dist && cp src/_redirects dist/_redirects && node build.js && cp src/robots.txt dist/robots.txt",
    "dev": "node src/js/generateSitemap.js && parcel build --dist-dir dist && node server.js",
    "build": "rimraf dist && yarn prebuild && parcel build --dist-dir dist && node src/js/generateSitemap.js && cp src/_redirects dist/_redirects && cp src/robots.txt dist/robots.txt",
    "prepare": "husky install",
    "check:tsc": "tsc --noemit --skipLibCheck -t es5 ./src/**/*.ts",
    "check:lint": "eslint ./src --ext .js,.ts",
    "check:format": "prettier --ignore-path .gitignore --write \"./src/**/*.+(js|ts|json|css|html)\""
  },
  "lint-staged": {
    "./src/**/*.{ts,js}": [
      "eslint --fix"
    ],
    "./src/**/*.{js,ts,html,css,json}": [
      "prettier --write"
    ]
  },
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "staticFiles": {
    "staticPath": "src/assets",
    "staticOutDir": "."
  },
  "source": [
    "src/pages/index.html",
    "src/pages/employers.html",
    "src/pages/legal.html",
    "src/pages/.well-known/change-password.html",
    "src/pages/assets.html",
    "src/pages/coin.html",
    "src/pages/trading.html",
    "src/pages/blog.html",
    "src/pages/blogPost.html",
    "src/pages/roth-account-calculator.html"
  ],
  "devDependencies": {
    "@alienlebarge/stylelint-config": "^3.1.6",
    "@types/node": "^17.0.41",
    "@types/smoothscroll-polyfill": "^0.3.1",
    "@types/three": "^0.141.0",
    "@typescript-eslint/eslint-plugin": "^5.21.0",
    "@typescript-eslint/parser": "^5.21.0",
    "buffer": "^6.0.3",
    "cross-env": "^7.0.3",
    "eslint": "^7.32.0",
    "eslint-config-prettier": "^8.5.0",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-prettier": "^4.0.0",
    "eslint-plugin-standard": "^5.0.0",
    "husky": "^7.0.4",
    "lint-staged": "^12.4.0",
    "parcel": "^2.4.1",
    "parcel-reporter-static-files-copy": "^1.3.4",
    "postcss-bem-linter": "^3.3.0",
    "posthtml-include": "^1.7.3",
    "prettier": "2.6.2",
    "process": "^0.11.10",
    "rimraf": "^3.0.2",
    "typescript": "^4.6.3"
  },
  "dependencies": {
    "@supabase/supabase-js": "^2.45.1",
    "axios": "^0.27.2",
    "chart.js": "^4.4.4",
    "express": "^4.21.1",
    "lil-gui": "^0.16.1",
    "markdown-it": "^14.1.0",
    "node-fetch": "^3.3.2",
    "smoothscroll-polyfill": "^0.4.4",
    "three": "^0.140.2",
    "universalsmoothscroll": "^5.1.2",
    "yup": "^0.32.11",
    "yup-phone": "^1.3.2"
  }
}
